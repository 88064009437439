body {
    background-color: #1E1E1E;
}

h1 {
    color: white;   
    font-family: 'Press Start 2P', Arial, Helvetica, sans-serif;
}
h2 {
    color: #D4D4D4;
    font-family: 'Roboto', sans-serif;
}

p {
    color: white;    
    font-family: 'Roboto', sans-serif;
    margin: 5% 10%;
}

/* Mobile or rotated monitor */
@media (orientation: portrait) {
    h1{
        font-size: 9vw;        
    }
    h2 {
        font-size: 7vw;
    }
    .Page-content {
        width: 90vw;
    }
}
/* Should work desktop and mobile landscape */
@media (orientation: landscape){
    h1{
        font-size: 4vw;        
    }
    h2 {
        font-size: 3vw;
    }
    .Page-content {
        width: 120vmin;
        max-width: 780px;
    }
}

.Full-page-message {
    position: absolute; 
    top: 20%;
    left: 0;
    right: 0;
}
  
.navbar {
    position: absolute;
    bottom: 0;
    width: 100%;
}



.navbar {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.Page-content {
    margin-left: auto; 
    margin-right: auto;    
    height: calc(100vh - 112px); 
    position: relative;
}

.Card-wrapper {
    display: flex;
    flex-wrap: wrap;
}